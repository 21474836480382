/* General Styles */
body {
  font-family: Bodoamat;
  font-size: 14px;
  line-height: 1.35;
  font-weight: 500;
  background-color: #fefaf7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Container and Flexbox */
.df-container {
  display: flex;
  justify-content: center;
}

.df-wrapper-padding {
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 2vh;
}

/* Header */
.df-header {
  font-size: 48px;
}

.df-navbar {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 90;
  width: 100%;
  height: 10vh;
}

.df-navbar-wrapper {
  height: 10vh;
  background-color: transparent;
}

.df-nav-grid {
  height: 100%;
  padding: 5px 30px;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-column-gap: 8px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.df-logo {
  z-index: 10;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 20px;
}

.df-logo-img {
  width: 120px;
  height: 100%;
}

.df-footer {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: left;
}

.df-ticker-img {
  width: 100%;
  height: 6vh;
  margin-right: 1vw;
  margin-left: 1vw;
}

.df-ticker-img.small {
  height: 6vh;
  margin-right: 1vw;
  margin-left: 1vw;
}

.df-ticker-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  transform: translateX(-100px);
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.df-ticker {
  display: none;
  padding-top: 6vh;
  padding-bottom: 6vh;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.df-mint-btn {
  font-size: 24px;
  font-weight: 400;
}

/* Ticker */
.df-ticker-img.small {
  height: 32px;
  margin-right: 10px;
  margin-left: 10px;
}

.df-ticker {
  padding-top: 5px;
  padding-bottom: 10px;
}

/* Card Grid Wrapper */
.card-grid-wrapper {
  padding: 0px;
}

.card-grid-wrapper:hover {
  background-color: transparent;
}

/* Accordion Header */
.accordion-header {
  padding-right: 20px;
  padding-left: 20px;
}

/* Hero */
.df-hero {
  width: 50%;
}

/* Overflow */
.df-overfow {
  display: flex;
  padding-top: 100px;
  padding-right: 25px;
  padding-left: 25px;
}

/* Media Queries */
@media screen and (max-width: 761px) {
  .df-wrapper-padding {
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 2vh;
  }
}

@media screen and (max-width: 760px) {
  .df-hero {
    width: 50%;
  }
}

@media screen and (max-width: 747px) {
  .df-header {
    font-size: 34px;
  }

  .df-nav-grid {
    display: flex;
    flex-direction: row;
  }

  .df-logo {
    justify-content: flex-start;
  }

  .df-mint-btn {
    font-size: 20px;
    font-weight: 400;
  }
}

@media screen and (max-width: 450px) {
  .df-header {
    font-size: 34px;
  }

  .df-footer-content {
    position: relative;
    padding-top: 5vh;
  }

  .df-nav-grid {
    display: flex;
    flex-direction: row;
  }

  .df-logo {
    justify-content: flex-start;
  }

  .df-mint-btn {
    font-size: 20px;
    font-weight: 400;
  }

  .df-hero {
    width: 80%;
  }
}

/* Custom Fonts */
@font-face {
  font-family: "Tanker";
  src: url("../assets/fonts/Tanker-Regular.woff") format("woff"),
       url("../assets/fonts/Tanker-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Bodoamat";
  src: url("../assets/fonts/BodoAmat.89e67a84324085ac5645e80742e57c43.otf")
    format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
